<template>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            Helios
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
          <b-col
            lg="4"
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
          >
            <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
            >
                <b-card-title
                        class="mb-1 font-weight-bold"
                        title-tag="h2"
                >
                    ¡Bienvenido a Helios! 👋
                </b-card-title>
                <amplify-auth-container>
                  <amplify-authenticator>
                      <amplify-sign-in
                              slot="sign-in"
                              username-alias="username"
                              hide-sign-up
                      ></amplify-sign-in>
                  </amplify-authenticator>
                </amplify-auth-container>
              <div class="divider my-2" v-if="true">
                <b-alert
                  variant="danger"
                  show
                   v-model="errorFlag"
                >
                  <div class="alert-body font-small-2">
                    <p>
                      <small class="mr-50"><span class="font-weight-bold">{{errorText}}</span></small>
                    </p>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-col>

      <!-- /Login-->
      </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText,
  BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: { },
      authState: undefined,
      status: '',
      errorText: '',
      errorFlag: false,
      loading: false,
      password: '',
      userEmail: '',
      loginData: '',
      userPhone: '',
      // password: '1234',
      // userEmail: 'carlos@helios.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
      // authentication state managament
      onAuthUIStateChange(async (state, user) => {
          // set current user and load data after login
          // console.log(state, user)
          if (state === AuthState.SignedIn) {
              this.user = user;
              await this.getUserApi(this.user.attributes.sub)
          }
      })
  },
  methods: {
    getUserApi(awsCognitoId){
        this.$axios.post(this.$services + "manager/getUserInfoApi", {
            awsCognitoId: awsCognitoId,
        })
            .then((res) => {
                if (!res.data.error) {
                    this.removeDataFromLocalStorage("user")
                    this.addDataToLocalStorage("user", res.data)

                    this.$router.replace('/home')
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Bienvenido ${res.data.name}`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: `No olvides revisar el apartado de precios para nuevas actualizaciones`,
                                    // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                },
                            })
                        })
                }
            })
    },
    addDataToLocalStorage(key, data){
        localStorage.setItem(key, JSON.stringify(data));
    },
    removeDataFromLocalStorage(key){
        localStorage.removeItem(key);
    },
  },
}

</script>


<style lang="scss">
:root {
  --amplify-primary-color: #7367f0;
  --amplify-primary-tint: #c4cccc;
  --amplify-primary-shade: #55595c;
}
@import '@core/scss/vue/pages/page-auth.scss';
</style>

